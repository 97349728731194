<script>
import { layoutMethods } from "@/state/helpers";
// import Switches from "vue-switches";

/**
 * Right-sidebar
 */
export default {
  components: {
    // Switches
  },
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"],
      },
      layout: this.$store ? this.$store.state.layout.layoutType : {} || {},
      width: this.$store ? this.$store.state.layout.layoutWidth : {} || {},
      topbar: this.$store ? this.$store.state.layout.topbar : {} || {},
      sidebarType: this.$store
        ? this.$store.state.layout.leftSidebarType
        : {} || {},
      loader: this.$store ? this.$store.state.layout.loader : {} || {},
    };
  },
  methods: {
    ...layoutMethods,
    hide() {
      this.$parent.toggleRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      if (event.target.classList)
        return !event.target.classList.contains("toggle-right");
    },
    changeLayout(layout) {
      this.changeLayoutType({
        layoutType: layout,
      });
    },
    changeType(type) {
      return this.changeLeftSidebarType({
        leftSidebarType: type,
      });
    },
    changeWidth(width) {
      return this.changeLayoutWidth({
        layoutWidth: width,
      });
    },
    changeTopbartype(value) {
      return this.changeTopbar({
        topbar: value,
      });
    },
    changeloader() {
      return this.changeLoaderValue({
        loader: this.loader,
      });
    },
  },
};
</script>

<template>
  <div>
    <!-- Right Sidebar -->
    <div v-click-outside="config" class="right-bar">
      <div data-simplebar class="h-100">
        <div class="rightbar-title px-3 py-4">
          <a
            href="javascript:void(0);"
            class="right-bar-toggle float-right"
            @click="hide"
          >
            <i class="mdi mdi-close noti-icon"></i>
          </a>
          <h5 class="m-0">Connections</h5>
        </div>


        <div class="p-3">
          <!-- Preloader -->
          <h6 class="mt-3">Distribution</h6>
          <hr class="mt-1" />

          <!-- <switches v-model="enabled1" type-bold="false" color="warning" class="ml-1 mb-0"></switches>Spotify
          <switches v-model="enabled2" type-bold="false" color="danger" class="ml-1 mb-0">Spotify</switches>
          <switches v-model="enabled3" type-bold="false" color="primary" class="ml-1 mb-0">Spotify</switches>
          <switches v-model="enabled4" type-bold="false" color="success" class="ml-1 mb-0">Spotify</switches>
          <switches v-model="enabled5" type-bold="false" color="info" class="ml-1 mb-0"></switches> -->

          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            > Spotify
          </b-form-checkbox>
          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            > Apple Music
          </b-form-checkbox>
          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            > Amazon
          </b-form-checkbox>
          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            > Tik-Tok
          </b-form-checkbox>
        </div>
        <div class="p-3">
          <!-- Preloader -->
          <h6 class="mt-3">Social Media</h6>
          <hr class="mt-1" />

          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            >Facebook
          </b-form-checkbox>
          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            >Instagram
          </b-form-checkbox>
          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            >Facebook
          </b-form-checkbox>
          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            >Twitter
          </b-form-checkbox>
        </div>

        <!-- Preloader -->
        <!-- <div class="p-3">
          <h6 class="mt-3">Preloader</h6>
          <hr class="mt-1" />

          <b-form-checkbox
            v-model="loader"
            name="check-button"
            switch
            @input="changeloader"
            >Preloader
          </b-form-checkbox
          >
        </div> -->
        <!-- Settings -->
        <!-- <hr class="mt-0" />
        <h6 class="text-center mb-0">Choose Layouts</h6>

        <div class="p-4">
          <div class="mb-2">
            <router-link
              to="//http://nazox.vuejs-light-h.themesdesign.in/"
              target="_blank"
            >
              <img
                src="@/assets/images/layouts/layout-4.jpg"
                class="img-fluid img-thumbnail"
                alt
              />
            </router-link>
          </div>

          <div class="mb-2">
            <router-link
              to="//http://nazox.vuejs-dark-h.themesdesign.in/"
              target="_blank"
            >
              <img
                src="@/assets/images/layouts/layout-5.jpg"
                class="img-fluid img-thumbnail"
                alt
              />
            </router-link>
          </div>

          <div class="mb-2">
            <router-link
              to="//http://nazox.vuejs-rtl-h.themesdesign.in/"
              target="_blank"
            >
              <img
                src="@/assets/images/layouts/layout-6.jpg"
                class="img-fluid img-thumbnail"
                alt
              />
            </router-link>
          </div>
        </div> -->
      </div>
      <!-- end slimscroll-menu-->
    </div>
    <!-- /Right-bar -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
  </div>
</template>
