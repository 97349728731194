<template>
<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        {{ YEAR }} © 99subs.io &bull; {{ SOURCE_VERSION }}
      </div>
      <div class="col-sm-6">
        <div class="text-sm-right d-none d-sm-block">
          Crafted with
          <i class="mdi mdi-heart text-danger"></i> by The Whittaker Group, Inc.
        </div>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
  import moment from 'moment';
  export default {
    data() {
      return {
        SOURCE_VERSION: process.env.VUE_APP_SOURCE_VERSION,
        YEAR: moment().year()
      };
    },
  }
</script>
